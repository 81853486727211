<template>
    <v-container fluid>
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2 border-12"
                                    hide-details=true 
                                    v-model="entiti" 
                                    :items="entitis" 
                                    default="" 
                                    item-value="Entity" 
                                    item-text="entity" 
                                    label="Entity ID"
                                    prepend-icon="mdi-garage"  
                                    @change="(event) => getRegion(event)"    
                                    :disabled="disabled_entity"
                                >
                                </v-autocomplete>
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2 border-12"
                                    hide-details=true 
                                    v-model="region" 
                                    :items="regions" 
                                    default="" 
                                    item-value="region" 
                                    item-text="region" 
                                    label="Region" 
                                    clearable
                                    prepend-icon="mdi-store"  
                                    @change="(event) => getOffice(event)"    
                                    :disabled="disabled_region"
                                >
                                </v-autocomplete>
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2 border-12"
                                    hide-details=true 
                                    v-model="office" 
                                    :items="offices" 
                                    default="" 
                                    item-value="office_id" 
                                    :item-text="item => item.office_id +' - '+ item.office" 
                                    label="Office" 
                                    clearable
                                    prepend-icon="mdi-home-assistant" 
                                    :disabled="disabled_office"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2 border-12"
                                    hide-details=true 
                                    v-model="brand" 
                                    :items="brands"
                                    item-value="brand_id" 
                                    item-text="brand_id" 
                                    label="Brand" 
                                    placeholder="Brand"
                                    clearable 
                                    prepend-icon="mdi-clipboard" 
                                >
                                </v-autocomplete>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="320px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date_field"
                                            label="Year"
                                            append-icon="event"
                                            v-bind="attrs"
                                            v-on="on"
                                            solo
                                            class="ma-0 pa-2 border-12"
                                            readonly
                                            hide-details=true
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="picker"
                                        v-model="date"
                                        :max="picker"
                                        @input="save"
                                        reactive
                                        no-title>
                                    </v-date-picker>
                                </v-menu>
                            </v-col> -->
                            <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="320px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="date_field"
                                                label="Year"
                                                append-icon="event"
                                                v-bind="attrs"
                                                v-on="on"
                                                solo
                                                class="ma-0 pa-2 border-12"
                                                readonly
                                                hide-details=true
                                            >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            ref="picker"
                                            v-model="date"
                                            @input="save"
                                            reactive
                                            no-title
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-2 border-12" 
                                color="info" elevation="2" large @click="SearchChart()">Search
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12">
            <v-card color="blue darken-2">
            <v-col class="col-12" id="showDetail">
                <v-card outlined class="pa-md-5  margin-top:25px" >
                    <h6 class="cyan-text text-darken-3 m-0">Result Chart Analyis Forecast</h6>
                    <div id="payment" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-card>
        </v-col>
            <!-- <v-row v-if="tableVisible">
            <v-col cols="12" id="showDetail">
                <v-card class="pa-md-5" style="background-color:#C5CAE9; margin-top:25px">
                    <h5 class="font-weight: 900; text-darken-3 white-text">Search Result</h5>
                    <div id="payment" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row> -->
        </v-row>
    </v-container>
</template>

<script>
import {filter} from "../../../backend-api/filter/index"
import {forecast} from "../../../backend-api/viva/forecast"
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data(){
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
        },
        timeout: 7500,
        search:'',
        breadcumbs: [
            {
                text: 'Viva',
                disabled: false,
                href: '/admin/viva',
            },
            {
                text: 'Forecast',
                disabled: false,
                href: '/admin/viva/dashboard',
            },
            {
                text: 'Chart Analysis',
                disabled: true,
            }
        ],
        entitis:[],
        regions:[],
        offices:[],
        brands:[],
        data_payments: [],
        date_field: '',
        picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        year: new Date().toISOString().substr(0, 4),
        menu: false,
        entiti:'',
        activePicker:'',
        date:'',
        region:'',
        office:'',
        brand:'',
        date_field :'',
        date_from:'',
        date_to:'',
        modal: false,
        modal_to: false, 
        disabled_entity: false,
        disabled_region: false,
        disabled_office: false,
        adaheDatatable : false,
        displaytable:false
        }
    },
    async mounted() { 
        // this.disabled_entity = true
        // this.disabled_region = true
        // this.disabled_office = true
        // await this.getEntity()
        // await this.getRegion()
        // await this.getOffice()
        this.tableVisible = false
        await this.getBrand()
        // await this.getEnvConf()
        await this.getEnvConfTwo()
        this.$refs.picker.activePicker = 'YEAR'    
    },    
    methods:{
        async getEnvConf(event){
            let varEntity = false
            let varRegion = false
            let varOffice = false
            const respData = await backendApi.fetchCore(`/api/get_group_user?entity_id=VIVA&appl_id=WEBVIVA`, null, false, false, false)
            
            if (respData.status === 200) {
                this.group_id = respData.data.data
                // console.log(this.group_id)
                let data = {
                    entity_id : 'VIVA',
                    appl_id : 'WEBVIVA',
                    var_id : ['WHOAMI', 'BRANCH', 'REGION'],
                    group_id : this.group_id
                }
                
                const respDataGroup = await forecast.fetchEnvConfGroup(data);
                if (respDataGroup.data.data.length > 0) {
                    for (let i = 0; i < respDataGroup.data.data.length; i++) {
                        console.log(respDataGroup.data.data[i]['var_id'])
                        if (respDataGroup.data.data[i]['var_id'] === 'WHOAMI') {
                            this.entiti = respDataGroup.data.data[i]['var_value']
                            if(this.entiti.includes(",")){
                                // console.log(this.entiti)
                                this.entitis = this.entiti.split(",");
                                this.entiti = this.entitis[0]
                            } else {
                                this.disabled_entity = true
                                varEntity = true
                            }
                        }
                        if (respDataGroup.data.data[i]['var_id'] === 'REGION') {
                            this.region = respDataGroup.data.data[i]['var_value']
                            this.disabled_region = true
                            varRegion = true
                        }
                        if (respDataGroup.data.data[i]['var_id'] === 'BRANCH') {
                            this.office = respDataGroup.data.data[i]['var_value']
                            this.disabled_office = true
                            varOffice = true
                        }
                    }
                }

                let dataUser = {
                    entity_id : 'VIVA',
                    appl_id : 'WEBVIVA',
                    var_id : ['WHOAMI', 'BRANCH', 'REGION'],
                }
                this.envUserData = await filter.fetchEnvConfUser(dataUser);
                if(this.envUserData.data.data.length > 0){
                    for (let i = 0; i < this.envUserData.data.data.length; i++) {
                        if (this.envUserData.data.data[i]['var_id'] === 'WHOAMI') {
                            this.entiti = this.envUserData.data.data[i]['var_value']
                            if(this.entiti.includes(",")){
                                // console.log(this.entiti)
                                this.entitis = this.entiti.split(",");
                                this.entiti = this.entitis[0]
                            } else {
                                this.disabled_entity = true
                                varEntity = true
                            }
                        }
                        if (this.envUserData.data.data[i]['var_id'] === 'REGION') {
                            this.region = this.envUserData.data.data[i]['var_value']
                            this.disabled_region = true
                            varRegion = true
                        }
                        if (this.envUserData.data.data[i]['var_id'] === 'BRANCH') {
                            this.office = this.envUserData.data.data[i]['var_value']
                            this.disabled_office = true
                            varOffice = true
                        }
                    }
                }
                    
            }
            if (!varEntity){
                this.disabled_entity = false
            }
            if (!varRegion){
                this.disabled_region = false
            }
            if (!varOffice){
                this.disabled_office = false
            }
                // console.log(`disabled_entity = ${this.disabled_entity}`);
        },   
        async getEnvConfTwo(){
            let varEntity = false
            let varRegion = false
            let varOffice = false
            let ent = []
            let reg = ''
            let off = ''
            const respData = await backendApi.fetchCore(`/api/get_group_user?entity_id=VIVA&appl_id=WEBVIVA`, null, false, false, false)
            if (respData.status === 200) {
                this.group_id = respData.data.data
                let data = {
                    entity_id : 'VIVA',
                    appl_id : 'WEBVIVA',
                    var_id : ['WHOAMI', 'BRANCH', 'REGION'],
                    group_id : this.group_id
                }
                let dataUser = {
                    entity_id : 'VIVA',
                    appl_id : 'WEBVIVA',
                    var_id : ['WHOAMI', 'BRANCH', 'REGION'],
                }
                const respDataGroup = await forecast.fetchEnvConfGroup(data);
                const respUserData = await filter.fetchEnvConfUser(dataUser);
                // console.log(respDataGroup)
                // console.log(respUserData)
                if (respDataGroup.data.data.length > 0) {
                    for (let i = 0; i < respDataGroup.data.data.length; i++) {
                        if (respDataGroup.data.data[i]['var_id'] === 'WHOAMI') {
                            let d = respDataGroup.data.data[i]['var_value']
                            if(d.includes(",")){
                                ent = d.split(",")
                            } else {
                                ent = [d]
                            }
                        }
                        if (respDataGroup.data.data[i]['var_id'] === 'REGION') {
                            reg = respDataGroup.data.data[i]['var_value']
                        }
                        if (respDataGroup.data.data[i]['var_id'] === 'BRANCH') {
                            off = respDataGroup.data.data[i]['var_value']
                        }
                    }
                }
                if (respUserData.data.data.length > 0) {
                    for (let i = 0; i < respUserData.data.data.length; i++) {
                        if (respUserData.data.data[i]['var_id'] === 'WHOAMI') {
                            let d = respUserData.data.data[i]['var_value']
                            if(d.includes(",")){
                                ent = d.split(",")
                            } else {
                                ent = [d]
                            }
                        }
                        if (respUserData.data.data[i]['var_id'] === 'REGION') {
                            reg = respUserData.data.data[i]['var_value']
                        }
                        if (respUserData.data.data[i]['var_id'] === 'BRANCH') {
                            off = respUserData.data.data[i]['var_value']
                        }
                    }
                }
                console.log(ent)
                let bodyData = {
                    "entity_id_in": ent,
                    "region": reg,
                    "office": off
                }
                await this.getAllFilter(bodyData)
            }
        },
        save(date) {
            console.log(date);

            this.$refs.menu.save(date)

            this.$refs.picker.activePicker = 'YEAR'

            this.menu = false

            this.date_field = new Date(date.substr(0, 4)).toISOString().substr(0, 4)
        },
        async getAllFilter(bodyData){
            var respData = await forecast.getFilterTwo(bodyData, null, false, false, false)
            if (respData.status === 200) {
                this.entitis = respData.data.data.entity
                this.entiti = this.entitis[0].entity
                if(this.entitis.length === 1){
                    this.disabled_entity = true
                }
                this.regions = respData.data.data.region
                if(this.regions.length === 1){
                    this.disabled_region = true
                }
                // this.region = this.regions[0]
                this.offices = respData.data.data.office
                if(this.offices.length === 1){
                    this.disabled_office = true
                }
                // this.office = this.offices[0]
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Connection problem, please refresh. If problem persist contact IT.',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                return false
            }
        },
        async getEntity(event){
            if (event === null || event === '') {
                this.entitis = ''
            } else{
                // this.$store.dispatch('setOverlay', true)
                var respData = await filter.fetchforecastfilter(`?entity_id=${this.entiti ? this.entiti : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    //console.log(respData)
                    this.entitis = respData.data.data.Entity
                    // this.$store.dispatch('setOverlay', false)
                }
            }
        },    
        async getRegion(event){
            if (event === null || event === '') {
                this.region = ''
                this.office = ''
            } else{
                // this.$store.dispatch('setOverlay', true)
                var respData = await filter.fetchforecastfilter(`?entity_id=${this.entiti ? this.entiti : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    // this.$store.dispatch('setOverlay', false)
                    this.regions = respData.data.data.Region
                    this.region = this.regions[0].region
                    this.getOffice(event)
                }
            }
        },
        async getOffice(event){
            if (event === null || event === '') {
                this.office = ''
            } else{
                // this.$store.dispatch('setOverlay', true)
                var respData = await filter.fetchforecastfilter(`?entity_id=${this.entiti ? this.entiti : ''}&region=${this.region ? this.region : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.offices = respData.data.data.Office
                    this.office = this.offices[0].office_id
                    // this.$store.dispatch('setOverlay', false)
                }
            }
        },
        async getBrand(event){
            // this.$store.dispatch('setOverlay', true)
            var respData = await filter.fetchbrand(`?entity_id=${this.entiti ? this.entiti : ''}`, null, false, false, false)
            if (respData.status === 200) {
                // this.$store.dispatch('setOverlay', false)
                this.brands = respData.data
            }
        
        },  
        async SearchChart(){
            if (this.entiti == '' || this.entiti === null || this.date == '' || this.date === null) {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Insert Complate Entity ID and Year !',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                this.dialog = true
                return false
            }
            this.$store.dispatch('setOverlay', true)
            var respData = await filter.fetchchart (`?entity_id=${this.entiti ? this.entiti : ''}&office=${this.office ? this.office : ""}&region=${this.region ? this.region : ""}&brand_id=${this.brand ? this.brand : ""}&year=${this.date_field ? this.date_field : ""}`, null, false, false, false)
            if (respData.status === 200) {
                console.log(respData)
                this.tableVisible = true
                // this.data_payments = respData.data
                this.renderChartBarPayment(respData.data)
                this.$store.dispatch('setOverlay', false)

            } else{
               this.$store.dispatch('setOverlay', false)
            }
        }, 
        renderChartBarPayment(data) {
        var chart3 = new CanvasJS.Chart("payment", {
                animationEnabled: true,
                theme: "light2",
                title: {
                text: "Forecast Analysis",
                fontFamily: "Calibri",
                fontSize: 20
                },
               axisX:{
                    title : "(Month)"
                },
                axisY:{
                    title : "(Ton)"
                },
        data: [
                 {
                        type: "line",
                        name: "Forecast",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        color: "#DD2C00",
                        indexLabel: "{ y }",
                        dataPoints: data.payment.map(item => ({
                        label: item.x,
                        y: parseFloat(item.forecast)
                        }))
                    },
                    {
                        type: "line",
                        name: "Realisasi",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        color: "#07dbf7",
                        indexLabel: "{ y }",
                         dataPoints: data.payment.map(item => ({
                        label: item.x,
                        y: parseFloat(item.realisasi)
                        }))
                    },
                    {
                        type: "line",
                        name: "Acuan",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        color: "#f79b07",
                        indexLabel: "{ y }",
                        dataPoints: data.payment.map(item => ({
                        label: item.x,
                        y: parseFloat(item.acuan)
                        }))
                    }
                ]
                
            });
            showDetail.style.display = "block";
            $('html, body').animate({
                scrollTop: $("#showDetail").offset().top
            }, 1200)

          chart3.render();
        },
    },
    watch: {
        menu (val) {
            console.log(val);
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
    }
}
</script>

<style>

</style>